import React from "react";
import { Helmet } from "react-helmet";
import "./ContactUs.css";

const ContactUs = () => {
    return (
        <div className="contact-us-background">
            <Helmet>
                <title>Contact Us - Issani</title>
                <meta
                    name="description"
                    content="Contact Issani for any queries, feedback, or collaborations. We'd love to hear from you!"
                />
            </Helmet>
            <div className="contact-us-container">
                <h1 className="fade-in">ಸಂಪರ್ಕಿಸಿ</h1>
                <div className="content-container fade-in">
                    <p>
                        ನಿಮಗೆ ಕನ್ನಡದಲ್ಲಿ ಕಥೆ, ಕವನ, ಲಘು ಪ್ರಬಂಧ ಅಥವಾ ಚಿಕ್ಕದೊಂದು ಸರಳ ಸನ್ನಿವೇಶವನ್ನು ಬರೆಯುವ ಆಸಕ್ತಿಯಿದೆಯೇ?! ಹಾಗಾದರೆ ನಿಮಗೆ ನಮ್ಮ ಇಸ್ಸನಿ ಬಳಗಕ್ಕೆ ಸ್ವಾಗತ...
                    </p>
                    <p>
                        ನಿಮ್ಮ ಬರಹಗಳನ್ನು ನಮ್ಮ ಈ ಕೆಳಗಿನ ವಿಳಾಸಕ್ಕೆ ಕಳುಹಿಸಿ....ನಿಮ್ಮ ಬರಹಗಳು ಪ್ರಕಟಣೆಗೆ ಯೋಗ್ಯವಾಗಿದ್ದರೆ, ನಮ್ಮ ಜಾಲತಾಣ ನಿಮ್ಮ ಬರಹಕ್ಕೆ ವೇದಿಕೆಯಾಗಲಿದೆ... ನಿಮ್ಮ ಬರಹದ ಜೊತೆಗೆ , ನಿಮ್ಮ ಹೆಸರು, ನಿಮ್ಮ ಚಿಕ್ಕ ಪರಿಚಯ ಹಾಗೂ ನಿಮ್ಮದೊಂದು ಭಾವಚಿತ್ರ ಕಳಿಸಿಕೊಡಿ..
                    </p>
                    <p>
                        ಆದರೆ ನೆನೆಪಿಡಿ ...ನಿಮ್ಮ ಬರಹವು ಯಾವುದೇ ಧರ್ಮ, ಜಾತಿ ಅಥವಾ ವ್ಯಕ್ತಿಗೆ ಅವಹೇಳನಕಾರಿಯಾಗಿದ್ದರೆ ಅಂತಹ ಬರಹಗಳನ್ನು ಪ್ರಕಟಿಸಲಾಗುವುದಿಲ್ಲ..ಸಂಪಾದಕೀಯ ತಂಡದ ತೀರ್ಮಾನವೇ ಅಂತಿಮ.
                    </p>
                    <p>
                        Mail ID: <strong>writeto@issani.co.in</strong>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
