import React from "react";
import { Helmet } from "react-helmet";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <Helmet>
        <title>ಗೌಪ್ಯತಾ ನೀತಿ - Issani</title>
        <meta name="description" content="Issani ಗೌಪ್ಯತಾ ನೀತಿ" />
      </Helmet>

      <h1>ಗೌಪ್ಯತಾ ನೀತಿ</h1>
      
      <section>
  <h2>ಲೇಖನಗಳ ಮೂಲ ಮತ್ತು ಹೊಣೆಗಾರಿಕೆ</h2>
  <p>
    "ಇಸ್ಸನಿ" ವೆಬ್ಸೈಟ್‌ನಲ್ಲಿ ಪ್ರಕಟವಾಗುವ ಲೇಖನಗಳು, ವಿಮರ್ಶೆಗಳು ಮತ್ತು ಇತರ ವಿಷಯಗಳು ಸ್ವತಂತ್ರ ಲೇಖಕರಿಂದ ಬರೆಯಲ್ಪಟ್ಟುವು. ಈ ಲೇಖನಗಳಲ್ಲಿ ವ್ಯಕ್ತಪಡಿಸಿದ ಅಭಿಪ್ರಾಯಗಳು ಮತ್ತು ಮಾಹಿತಿಗಳು ಮೂಲ ಲೇಖಕರ ವೈಯಕ್ತಿಕ ದೃಷ್ಟಿಕೋನವನ್ನು ಪ್ರತಿಬಿಂಬಿಸುತ್ತವೆ. "ಇಸ್ಸನಿ" ಯಾವುದೇ ಲೇಖನದ ವಿಷಯ ಸಂಬಂಧಿಸಿದ ನೇರ ಅಥವಾ ಪರೋಕ್ಷ ಹೊಣೆಗಾರಿಕೆಯನ್ನು ಒಪ್ಪುವುದಿಲ್ಲ.
  </p>
</section>

<section>
  <h2>ಹಕ್ಕುಗಳು ಮತ್ತು ಪ್ರತಿಕೃತಿ ನಿಯಮಗಳು</h2>
  <p>
    ವೆಬ್ಸೈಟ್‌ನಲ್ಲಿ ಪ್ರಕಟವಾಗಿರುವ ಎಲ್ಲ ಲೇಖನಗಳು, ದೃಶ್ಯಗಳು ಮತ್ತು ಇತರ ಮಾಹಿತಿಗಳ ಸಂಪೂರ್ಣ ಹಕ್ಕುಗಳು ಮೂಲ ಲೇಖಕರಿಗೆ ಮೀಸಲಾಗಿವೆ. ಯಾವುದೇ ಲೇಖನವನ್ನು ಪೂರ್ವ ಅನುಮತಿ ಪಡೆದುಕೊಳ್ಳದೆ ಮುದ್ರಣ, ಡಿಜಿಟಲ್ ಪ್ರಚಾರ ಅಥವಾ ಪುನರುತ್ಪಾದನೆ ಮಾಡಲು ನಿಷೇಧಿಸಲಾಗಿದೆ. ಲೇಖಕರು ತಮ್ಮ ಲೇಖನಗಳ ಸಂಪೂರ್ಣ ಹಕ್ಕನ್ನು ಕಾಪಾಡಿಕೊಂಡಿದ್ದಾರೆ.
  </p>
</section>

<section>
  <h2>ವಿಷಯ ಬಳಕೆ ಹಾಗೂ ಉತ್ತರದಾಯಕತೆ</h2>
  <p>
    "ಇಸ್ಸನಿ" ನಲ್ಲಿ ಲಭ್ಯವಿರುವ ಮಾಹಿತಿಯನ್ನು ಓದುತ್ತಾ ಮತ್ತು ಬಳಸುವ ಪ್ರತಿಯೊಬ್ಬರು, ಅದರ ನಿಖರತೆ, ಸಮಗ್ರತೆ ಮತ್ತು ಪ್ರಸ್ತುತತೆಯನ್ನು ಸ್ವತಂತ್ರವಾಗಿ ಪರಿಶೀಲಿಸುವ ಜವಾಬ್ದಾರಿಯನ್ನು ಹೊಂದಿರುತ್ತಾರೆ. ಯಾವುದೇ ನೇರ ಅಥವಾ ಪರೋಕ್ಷ ಪರಿಣಾಮಗಳಿಂದ ಉಂಟಾಗುವ ನಷ್ಟ, ಹಾನಿ ಅಥವಾ ಗೊಂದಲಗಳಿಗೆ "ಇಸ್ಸನಿ" ಹೊಣೆಗಾರಿಕೆಯನ್ನು ಒಪ್ಪುವುದಿಲ್ಲ.
  </p>
</section>

<section>
  <h2>ಬಾಹ್ಯ ಲಿಂಕ್‌ಗಳು ಮತ್ತು ಉಲ್ಲೇಖಗಳು</h2>
  <p>
    ನಮ್ಮ ವೆಬ್ಸೈಟ್‌ನಲ್ಲಿ ಉಲ್ಲೇಖಿಸಲಾದ ಬಾಹ್ಯ ಲಿಂಕ್‌ಗಳು ಮತ್ತು ಸಂಪನ್ಮೂಲಗಳು ತೃತೀಯ ಪಕ್ಷದ ಮಾಹಿತಿಯನ್ನು ಸೂಚಿಸುತ್ತವೆ. ಈ ಲಿಂಕ್‌ಗಳಲ್ಲಿ ಪ್ರಸ್ತುತಪಡಿಸಿರುವ ವಿಷಯ, ನಿಯಮಗಳು ಮತ್ತು ನಿಖರತೆ ಕುರಿತು "ಇಸ್ಸನಿ" ಯಾವುದೇ ಹೊಣೆಗಾರಿಕೆಯನ್ನು ಸ್ವೀಕರಿಸುವುದಿಲ್ಲ.
  </p>
</section>

<section>
  <h2>ಸಂಶೋಧನೆ ಮತ್ತು ನೀತಿ ಬದಲಾಗುವಿಕೆ</h2>
  <p>
    ವೆಬ್ಸೈಟ್‌ನಲ್ಲಿ ಪ್ರಕಟಿಸಿದ ಮಾಹಿತಿಯನ್ನು ನಿಯಮಿತವಾಗಿ ಪರಿಷ್ಕರಿಸಲಾಗುತ್ತದೆ. ಈ ನೀತಿಯನ್ನು ಯಾವುದೇ ಸಮಯದಲ್ಲಿ ಬದಲಾಯಿಸಬಹುದಾಗಿದೆ ಮತ್ತು ಬದಲಾಗುವ ನೀತಿಯ ಬಗ್ಗೆ ಬಳಕೆದಾರರು ಸ್ವತಂತ್ರವಾಗಿ updated version ಅನ್ನು ಪರಿಶೀಲಿಸಬೇಕು.
  </p>
</section>

<section>
  <h2>ಸಂಪರ್ಕಿಸಿ</h2>
  <p>
    ನಮ್ಮ ಗೌಪ್ಯತಾ ನೀತಿ ಮತ್ತು ವೆಬ್ಸೈಟ್‌ನ ವಿಷಯ ಬಳಕೆ ಬಗ್ಗೆ ನಿಮಗೆ ಯಾವುದೇ ಪ್ರಶ್ನೆಗಳಿದ್ದಲ್ಲಿ, ದಯವಿಟ್ಟು ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ. ನಿಮ್ಮ ಪ್ರತಿಕ್ರಿಯೆ ಮತ್ತು ಪ್ರಶ್ನೆಗಳಿಗೆ ನಾವು ಸಮರ್ಪಕವಾಗಿ ಸ್ಪಂದಿಸುವೆವು.
  </p>
</section>

    </div>
  );
};

export default PrivacyPolicy; 