import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./AnkanaHorizontalScroll.css";

const AnkanaHorizontalScroll = () => {
  const [ankanas, setAnkanas] = useState([]);
  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(true);
  const [startX, setStartX] = useState(0);
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    const fetchTopAnkanas = async () => {
      try {
        const response = await fetch(
          "https://issani.co.in/api/api.php?api=getAnkanas&limit=20"
        );
        const data = await response.json();
        setAnkanas(data);
      } catch (error) {
        console.error("Error fetching top ankanas:", error);
      }
    };

    fetchTopAnkanas();
  }, []);

  const checkScrollPosition = () => {
    const container = scrollContainerRef.current;

    if (!container) return;

    setIsLeftDisabled(container.scrollLeft === 0);
    setIsRightDisabled(
      container.scrollLeft + container.offsetWidth >= container.scrollWidth
    );
  };

  const scrollLeft = () => {
    const container = scrollContainerRef.current;
    container.scrollBy({
      left: -300,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    const container = scrollContainerRef.current;
    container.scrollBy({
      left: 300,
      behavior: "smooth",
    });
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0].clientX;
    const distance = startX - endX;

    if (distance > 50) {
      // Swipe left
      scrollRight();
    } else if (distance < -50) {
      // Swipe right
      scrollLeft();
    }
  };

  useEffect(() => {
    const container = scrollContainerRef.current;

    if (container) {
      // Add scroll and touch event listeners
      container.addEventListener("scroll", checkScrollPosition);
      container.addEventListener("touchstart", handleTouchStart);
      container.addEventListener("touchend", handleTouchEnd);

      // Initial scroll check
      checkScrollPosition();

      return () => {
        container.removeEventListener("scroll", checkScrollPosition);
        container.removeEventListener("touchstart", handleTouchStart);
        container.removeEventListener("touchend", handleTouchEnd);
      };
    }
  }, [startX, ankanas]);

  return (
    <div className="ankana-scroll-container">
      <h3>ಅಂಕಣಗಳು</h3>
      <div className="ankana-scroll-buttons">
        <button
          onClick={scrollLeft}
          className="scroll-button left"
          disabled={isLeftDisabled}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <div className="ankana-scroll-wrapper" ref={scrollContainerRef}>
          {ankanas.map((ankana) => (
            <div key={ankana.Ank_id} className="ankana-item">
              <a href={`/ankana/${ankana.Ank_id}`}>
                <img
                  src={`/assets/${ankana.imgPath}`}
                  alt={ankana.name || "Ankana"}
                  className="ankana-thumbnail larger-thumbnail" // Add a new class for larger thumbnails
                />
              </a>
            </div>
          ))}
        </div>
        <button
          onClick={scrollRight}
          className="scroll-button right"
          disabled={isRightDisabled}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    </div>
  );
};

export default AnkanaHorizontalScroll;