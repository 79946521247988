import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ShareButtons from "./ShareButtons";
import "./AnkanaDetails.css";
import CommentForm from "./CommentForm";
import CommentList from "./CommentList";
import Spinner from "../components/Spinner";

const AnkanaDetails = () => {
  const { ankaId } = useParams();
  const [latestPost, setLatestPost] = useState(null);
  const [otherPosts, setOtherPosts] = useState([]);
  const [image, setImage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnkanaDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `https://issani.co.in/api/api.php?api=getAnkanaLatestPostAndPosts&anka_id=${ankaId}&page=1&limit=5`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch Ankana details.");
        }

        const data = await response.json();

        if (!data || !data.ankanaDetails) {
          throw new Error("Invalid data format received.");
        }

        setImage(
          data.ankanaDetails.imgPath
            ? `/assets/${data.ankanaDetails.imgPath}`
            : "/assets/default-banner.jpg"
        );

        setLatestPost(data.latestPost || null);
        setOtherPosts(data.otherPosts || []);
        setTotalPages(data.pagination?.total_pages || 1);
      } catch (error) {
        console.error("Error fetching Ankana details:", error);
        setError("Unable to load Ankana details. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchAnkanaDetails();
  }, [ankaId]);

  const loadMorePosts = async () => {
    if (currentPage >= totalPages) return;

    // Save the current scroll position
    const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

    setLoading(true);
    try {
      const response = await fetch(
        `https://issani.co.in/api/api.php?api=getAnkanaLatestPostAndPosts&anka_id=${ankaId}&page=${
          currentPage + 1
        }&limit=5`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch more posts.");
      }

      const data = await response.json();
      setOtherPosts((prevPosts) => [...prevPosts, ...(data.otherPosts || [])]);
      setCurrentPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Error loading more posts:", error);
      setError("Unable to load more posts. Please try again later.");
    } finally {
      setLoading(false);
      // Scroll back to the saved position
      window.scrollTo(0, currentScrollPosition);
    }
  };

  const processContent = (content, imgUrl) => {
    if (!content) {
      return ""; // Return an empty string if content is undefined or null
    }

    let processedContent = content;

    // Replace *img* tags with the actual image
    if (imgUrl) {
      processedContent = processedContent.replace(
        /\*img\*/g,
        `<img src="${imgUrl}" alt="Post Image" class="inline-post-image" />`
      );
    }

    // Highlight text between q and cq
    processedContent = processedContent.replace(
      /q(.*?)cq/g,
      '<span class="highlight-text">$1</span>'
    );

    // Retain line breaks
    processedContent = processedContent.replace(/\n/g, "<br />");

    return processedContent;
  };

  if (loading && !latestPost && otherPosts.length === 0) {
    return <Spinner />;
  }

  if (error) {
    return (
      <div className="error-message-container">
        <div className="error-message">
          <h1>Oops!</h1>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (!latestPost && otherPosts.length === 0) {
    return <div className="no-data-message">No Ankana details available.</div>;
  }

  return (
    <div className="ankana-details-container">
      <Helmet>
        <title>{latestPost?.title || "Ankana Details"} - Issani</title>
        <meta
          name="description"
          content={`Read more from this Ankana: ${latestPost?.title}`}
        />
        <meta property="og:title" content={latestPost?.title || "Ankana Details"} />
        <meta property="og:description" content={`${latestPost?.body?.substring(0, 150)}...`} />
        <meta property="og:image" content={`https://issani.co.in/${latestPost?.img || "assets/default-banner.jpg"}`} />
        <meta property="og:url" content={`https://issani.co.in/ankana/${ankaId}`} />
        <meta property="og:type" content="article" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content={latestPost?.title || "Ankana Details"} />
        <meta property="twitter:description" content={`${latestPost?.body?.substring(0, 150)}...`} />
        <meta property="twitter:image" content={`https://issani.co.in/${latestPost?.img || "assets/default-banner.jpg"}`} />
      </Helmet>

      {/* Display Ankana Banner */}
      <img
        src={image}
        alt="Ankana Banner"
        className="ankana-banner"
        loading="lazy"
      />

      {/* Share Buttons */}
      <ShareButtons
        url={`https://issani.co.in/ankana/${ankaId}`}
        title={latestPost?.title || ""}
        authorName={latestPost?.author_name || ""}
        imgUrl={`https://issani.co.in/${latestPost?.img || ""}`}
        contentSnippet={`${latestPost?.body?.substring(0, 100)}...`}
      />

      {/* Display Latest Post */}
      {latestPost && (
        <div className="latest-post">
          <h1>{latestPost.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: processContent(
                latestPost.body || "", // Fallback to an empty string
                `https://issani.co.in/${latestPost.img}`
              ),
            }}
          />
          
          {/* Add Comment Section */}
          <div className="comments-section">
            
            <CommentForm postId={String(latestPost.bid)} />
            <CommentList postId={String(latestPost.bid)} />
          </div>
        </div>
      )}

      {/* Display Other Posts */}
      {otherPosts.length > 0 && (
        <>
          <h1>ಈ ಅಂಕಣದಲ್ಲಿರುವ ಇತರ ಬರಹಗಳು</h1>
          <div className="other-posts">
            {otherPosts.map((post) => (
              <div key={post.bid} className="poster-card">
                <h3>
                  <a href={`/post/${post.bid}`}>{post.title}</a>
                </h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: processContent(
                      post.body?.substring(0, 150) || "",
                      `https://issani.co.in/${post.img || ""}`
                    ),
                  }}
                />
              </div>
            ))}
          </div>

          {/* Load More Button */}
          {currentPage < totalPages && (
            <div className="load-more-container">
              <button
                className="load-more-button"
                onClick={loadMorePosts}
                disabled={loading}
              >
                {loading ? "Loading..." : "ಇನ್ನಷ್ಟು ಪೋಸ್ಟ್ಗಳು"}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AnkanaDetails;