import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Layout.css";

const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [cookieAccepted, setCookieAccepted] = useState(
    localStorage.getItem("cookieAccepted") === "true"
  );

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const acceptCookies = () => {
    localStorage.setItem("cookieAccepted", "true");
    setCookieAccepted(true);
  };

  return (
    <div className="layout-wrapper">
      {/* Header with Logo and Navigation */}
      <header className="header">
        <div className="logo">
          <Link to="/">
            <img src="/assets/logo.png" alt="Website Logo" />
          </Link>
        </div>
        {/* Hamburger Menu */}
        <button
          className="hamburger"
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          ☰
        </button>
        <nav className={`navbar ${menuOpen ? "open" : ""}`}>
          <Link to="/" onClick={() => setMenuOpen(false)}>
            ಮನೆ
          </Link>
          <Link to="/list-authors" onClick={() => setMenuOpen(false)}>
            ಲೇಖಕರು
          </Link>
          <Link to="/all-ankanas" onClick={() => setMenuOpen(false)}>
            ಅಂಕಣ ಬರಹಗಳು
          </Link>
          <Link to="/about" onClick={() => setMenuOpen(false)}>
            ನಮ್ಮ ಬಗ್ಗೆ
          </Link>
          <Link to="/contact" onClick={() => setMenuOpen(false)}>
            ಸಂಪರ್ಕಿಸಿ
          </Link>
        </nav>
      </header>

      {/* Cookie Banner */}
      {!cookieAccepted && (
        <div className="cookie-banner">
          <p>
            ನಾವು ನಿಮ್ಮ ಬ್ರೌಸಿಂಗ್ ಅನುಭವವನ್ನು ಸುಧಾರಿಸಲು ಕುಕೀಗಳನ್ನು ಬಳಸುತ್ತೇವೆ. 
            <Link to="/privacy-policy">ಗೌಪ್ಯತಾ ನೀತಿ</Link> ನೋಡಿ.
          </p>
          <button onClick={acceptCookies}>ಒಪ್ಪಿಕೊಳ್ಳಿ</button>
        </div>
      )}

      {/* Main Content */}
      <main className="main-content">{children}</main>

      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <p>
              © 2025 Issani.co.in - ಎಲ್ಲ ಹಕ್ಕುಗಳನ್ನು ಕಾಯ್ದಿರಿಸಲಾಗಿದೆ
            </p>
            <p>
              ಸಂಪರ್ಕ ಮಾಹಿತಿ: <a href="mailto:writeto@issani.co.in">WriteTo@issani.co.in</a>
            </p>
          </div>
          <div className="footer-links">
            <Link to="/privacy-policy">ಗೌಪ್ಯತಾ ನೀತಿ </Link>
            <Link to="/contact">ಸಂಪರ್ಕಿಸಿ </Link>
            <Link to="/about">ನಮ್ಮ ಬಗ್ಗೆ</Link>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
