import React, { useEffect, useState } from "react";
import "./ListAllAnkanas.css";

const ListAllAnkanas = () => {
  const [ankanas, setAnkanas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 6;

  useEffect(() => {
    const fetchAllAnkanas = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://issani.co.in/api/api.php?api=getAnkanas&limit=${limit}&page=${page}`
        );
        if (!response.ok) throw new Error("Failed to fetch Ankanas.");
        const data = await response.json();
        if (Array.isArray(data)) {
          setAnkanas(data);
          setHasMore(data.length === limit);
        } else {
          throw new Error("Invalid API response.");
        }
      } catch (error) {
        console.error("Error fetching all ankanas:", error);
        setError("Unable to load Ankanas. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchAllAnkanas();
  }, [page]);

  const handleNextPage = () => {
    if (hasMore) setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  if (loading) return <p className="loading-message">Loading Ankanas...</p>;
  if (error) return <p className="error-message">{error}</p>;

  return (
    <div className="ankanas-background">
      <h1 className="ankanas-heading">ಅಂಕಣಗಳು</h1>
      <div className="ankanas-grid">
        {ankanas.map((ankana) => (
          <a key={ankana.Ank_id} href={`/ankana/${ankana.Ank_id}`} className="ankana-image">
            <img
              src={ankana.imgPath ? `/assets/${ankana.imgPath}` : "/assets/default-banner.jpg"}
              alt={ankana.name || "Ankana"}
              className="ankana-thumbnail"
            />
          </a>
        ))}
      </div>
      <div className="pagination-controls">
        <button
          className="pagination-button"
          onClick={handlePreviousPage}
          disabled={page === 1}
        >
          Previous
        </button>
        <span>Page {page}</span>
        <button
          className="pagination-button"
          onClick={handleNextPage}
          disabled={!hasMore}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ListAllAnkanas;