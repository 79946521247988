import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import ShareButtons from "./ShareButtons";
import CommentForm from "./CommentForm";
import CommentList from "./CommentList";
import "./PostDetails.css";
import { collection, query, where, onSnapshot, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from './../firebase/firebase.js';
import Spinner from "../components/Spinner";

const PostDetails = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const fetchPostDetails = async () => {
      try {
        const response = await fetch(
          `https://issani.co.in/api/api.php?api=getPost&post_id=${postId}`
        );
        const data = await response.json();
        setPost(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching post details:", error);
      }
    };

    if (postId) {
      fetchPostDetails();
    }
  }, [postId]);

  useEffect(() => {
    const fetchComments = () => {
      const commentsQuery = query(
        collection(db, "comments"),
        where("postId", "==", postId)
      );

      const unsubscribe = onSnapshot(commentsQuery, (snapshot) => {
        const fetchedComments = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setComments(fetchedComments);
      });

      return () => unsubscribe();
    };

    if (postId) {
      fetchComments();
    }
  }, [postId]);

  const handleAddComment = async (authorName, commentText) => {
    try {
      await addDoc(collection(db, "comments"), {
        postId,
        authorName,
        commentText,
        timestamp: serverTimestamp(),
      });
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const processContentWithHighlightsAndImage = (body, imgUrl) => {
    if (!body) return "";

    let processedContent = body;

    // Replace *img* with the actual image tag
    if (imgUrl) {
      processedContent = processedContent.replace(
        /\*img\*/g,
        `<img src="${imgUrl}" alt="Post Image" class="inline-post-image" />`
      );
    }

    // Highlight text between *q* and *cq*
    processedContent = processedContent.replace(
      /\*q\*(.*?)\*cq\*/gs,
      '<span class="highlight-text">$1</span>'
    );

    return processedContent;
  };

  // Format date for display
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (loading) {
    return <Spinner />;
  }

  if (!post) {
    return <div>No post details available.</div>;
  }

  const imgUrl = post.img ? `https://issani.co.in/${post.img}` : null;

  // Structured Data for SEO
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: post.title,
    image: imgUrl,
    author: {
      "@type": "Person",
      name: post.author_name,
      url: `https://issani.co.in/author/${post.author}`,
    },
    publisher: {
      "@type": "Organization",
      name: "Issani",
      logo: {
        "@type": "ImageObject",
        url: "https://issani.co.in/assets/logo.png",
      },
    },
    datePublished: post.postedon,
    description: post.body.substring(0, 150),
  };

  return (
    <div className="post-details-container">
      {/* Dynamically Update Page Title */}
      <Helmet>
    <title>{post.title} - Issani</title>
    <meta name="description" content={`Read the full post: ${post.title}`} key="description" />
    <meta property="og:title" content={post.title} key="og:title" />
    <meta property="og:description" content={`${post.body.substring(0, 150)}...`} key="og:description" />
    <meta property="og:image" content={imgUrl} key="og:image" />
    <meta property="og:url" content={`https://issani.co.in/post/${postId}`} key="og:url" />
    <meta property="og:type" content="article" key="og:type" />
</Helmet>


      {/* Post Content */}
      <h1>{post.title}</h1>
      <div className="author-name">
        <span>ಲೇಖಕರು: </span>
        <a href={`/author/${post.author}`}>{post.author_name}</a>
      </div>
      <div className="post-date">
        <span>ಪ್ರಕಟಿಸಿದ ದಿನಾಂಕ: {formatDate(post.postedon)}</span>
      </div>

      {/* Share Buttons */}
      <ShareButtons
        url={`https://issani.co.in/post/${postId}`}
        title={post?.title || ""}
        authorName={post?.author_name || ""}
        imgUrl={imgUrl}
        contentSnippet={`${post?.body?.substring(0, 100)}...`}
      />

      {imgUrl && !post.body.includes("*img*") && (
        <img
          src={imgUrl}
          alt="top"
          className="post-top-image inline-post-image"
        />
      )}

      {/* Main Content */}
      <div
        className="post-content"
        dangerouslySetInnerHTML={{
          __html: processContentWithHighlightsAndImage(
            post.body.replace(/\n/g, "<br />"),
            imgUrl
          ),
        }}
      />

      {/* Comments Section */}
      <div className="comments-section">
        <h2>Comments</h2>
        <CommentForm onAddComment={handleAddComment} postId={postId} />
        <CommentList comments={comments} postId={postId} />
      </div>
    </div>
  );
};

export default PostDetails;
